













import {Vue, Component} from "vue-property-decorator";
import {Stores} from "@/request/plat/Stores";

@Component({
    name: 'plat_stores'
})
export default class plat_stores extends Vue {
    modal: any = Stores;
    search: Array<any> = [
        {
            type: 'Input',
            label: '商户ID：',
            key: 'merchantId',
            width: '200px',
            clearable: true,
            placeholder: '请填写商户ID'
        }
    ];
    columns: Array<any> = [
        {
            title: '商户ID',
            key: 'merchantId',
            minWidth: 180
        },
        {
            title: '商户名字',
            key: 'merchantName',
            minWidth: 160
        },
        {
            title: '店铺名字',
            key: 'name',
            minWidth: 120
        },
        {
            title: '状态',
            key: 'status',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.status===0?'关闭': p.row.status===1?'正常':'-')
        },
        {
            title: '店铺电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '认证用户数量',
            key: 'authNum',
            minWidth: 120
        },
        {
            title: '交易金额',
            key: 'transFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.transFee / 100).toFixed(2))
        },
        {
            title: '交易次数',
            key: 'transNum',
            minWidth: 120
        },
        {
            title: '用户使用量',
            key: 'userNum',
            minWidth: 120
        },
        {
            title: '店铺图片',
            key: 'images',
            minWidth: 140,
            tooltip: true,
            render: (h: any, p: any) => {
                let self: any = this;
                let imgs: Array<any> = [];
                p.row.images.forEach((j: any, index: number) => {
                    imgs.push(h('img', {
                        style: {
                            borderRadius: '3px',
                            height: '40px',
                            width: '40px',
                            margin: '5px 2px 0',
                            cursor: 'pointer'
                        },
                        attrs: {
                            src: j
                        },
                        on: {
                            click() {
                                self.$refs['image_preview'].show(j);
                            }
                        }
                    }))
                })
                return h('div', {
                    style: {
                        display: 'flex'
                    }
                }, imgs)
            }
        },
        {
            title: '省市区',
            key: 'province',
            minWidth: 200,
            render: (h: any, p: any) => h('p', `${p.row.province} ${p.row.city} ${p.row.county}`)
        },
        {
            title: '门店地址',
            key: 'address',
            tooltip: true,
            minWidth: 160
        },
        {
            title: '描述',
            key: 'desc',
            tooltip: true,
            minWidth: 160
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ]
}
